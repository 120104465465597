<template>
  <div class="admin-order_info">
    <div class="cell">
      <div class="title">订单详情</div>
      <div>
        <div class="item" style="font-weight: 600">订单信息</div>
        <div class="item">
          <div style="width: 33%">订单号：{{ infoData.order_no }}</div>
          <div style="width: 33%">订单状态：{{ type[infoData.state] }}</div>
          <div style="width: 33%">
            订单总额：
            <span style="color: #ff6f29"> RM{{ infoData.pay_price }}</span>
          </div>
        </div>
        <div class="item">
          <div style="width: 33%">买家账号：{{ infoData.member_name }}</div>
          <div style="width: 33%">店铺账号：{{ infoData.shop_username }}</div>
          <div style="width: 33%">
            支付方式：{{ infoData.pay_type == 0 ? "货到付款" : "虚拟支付" }}
          </div>
          <div style="width: 33%; margin-top: 10px">
            下单时间：{{ infoData.create_time }}
          </div>
          <div
            v-if="infoData.payment_time"
            style="width: 33%; margin-top: 10px"
          >
            支付时间：{{ infoData.payment_time }}
          </div>
          <div v-if="infoData.take_time" style="width: 33%; margin-top: 10px">
            完成时间：{{ infoData.take_time }}
          </div>
        </div>
        <div class="item" style="font-weight: 600">收货人信息</div>
        <div class="item" style="background: #fbfbfb" v-if="infoData.address">
          <div style="width: 33%">收货人姓名：{{ infoData.address.name }}</div>
          <div style="width: 33%">电话号码：{{ infoData.address.mobile }}</div>
          <div style="width: 33%">
            详细地址：{{
              infoData.address.country +
              infoData.address.province +
              infoData.address.city +
              infoData.address.district +
              infoData.address.address
            }}
          </div>
        </div>
        <div class="item" style="font-weight: 600">商品信息</div>
        <div class="item">
          <div class="tab">
            <div class="tab-head">
              <div style="width: 5%"></div>
              <div style="width: 50%">商品信息</div>
              <div style="width: 10%">原价</div>
              <div style="width: 10%">折扣价</div>
              <div style="width: 10%">运费</div>
              <div style="width: 10%">数量</div>
              <div style="width: 10%">实际支付</div>
            </div>
            <div
              class="tab-contenr"
              v-for="item in infoData.goods"
              :key="item.id"
            >
              <div style="display: flex; width: 55%">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
                <div style="margin-left: 10px; width: 500px" class="row-hidden">
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 10%">
                <span style="color: #ff6f29"> RM{{ item.price }}</span>
              </div>
              <div style="width: 10%">
                RM{{
                  item.discount_price == 0 ? item.price : item.discount_price
                }}
              </div>
              <div style="width: 10%">RM{{ item.freight_price }}</div>
              <div style="width: 10%">{{ item.number }}</div>
              <div style="width: 10%; color: #ff6f29">
                RM{{ item.actual_payment }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-button
        type="primary"
        @click="handleBack"
        size="mini"
        style="margin-top: 20px"
        >返回</el-button
      >
    </div>
  </div>
</template>
<script>
import { orderInfo } from "@/api/admin.js";
export default {
  data() {
    return {
      infoData: "",
      type: [
        "",
        "待付款",
        "待出货",
        "待发货",
        "运送中",
        "已完成",
        "已取消",
        "退货/退款",
      ],
    };
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    getOrderInfo() {
      orderInfo({
        order_id: sessionStorage.ADMIN_ORDERID,
      }).then((res) => {
        if (res.code == 1) {
 
          this.infoData = res.data;
        }
      });
    },
    handleBack() {
      this.$router.replace("adminOrderList");
    },
  },
};
</script>
<style lang="less" scoped>
.admin-order_info {
  .cell {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    .title {
      font-size: 18px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }
    .item {
      padding: 8px 0;
      font-size: 12px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      padding-left: 20px;
      flex-wrap: wrap;
    }
    .tab {
      width: 100%;
      font-size: 12px;
      border: 1px solid #cbe9f3;
      .tab-head {
        display: flex;
        align-items: center;
        background: #f3fbfe;
        padding: 10px;
        font-weight: bold;
        border-bottom: 1px solid #eee;
      }
      .tab-contenr {
        padding: 10px;
        display: flex;
      }
    }
  }
}
</style>